body {
    display: flex;
    background: rgb(55, 56, 78);
    color: #fff;
}

#root {
    display: flex;
    flex: 0 0 100%;
    flex-direction: column;
    justify-content: space-between;

    header {
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 1rem;        
    }

    footer {
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border-top: 1px solid rgba(244,244,244,.2);
        font-size: 12px;
        opacity: .7;
    }
    @media (min-width: 768px) {

        & > .container {

            @media (min-width: 768px) {
                display: flex;
            }

            & > .row {
                @media (min-width: 768px) {
                    flex: 0 0 100%;
                }

                & > div {
                    @media (min-width: 768px) {
                        display: flex;
                        align-items: center;
                        max-height: 100%;

                        &:first-child {
                            justify-content: center;
                            align-items: flex-end;
                            text-align: right;
                            flex-direction: column;
                            position: relative;
                        }
                    }

                    .input-group-text {
                        min-width: 80px;
                    }

                    .form-control {
                        max-width: 100px;
                        text-align: center;
                    }

                    button {
                        min-width: 80px;

                        &.btn-sm {
                            font-size: 11px;
                        }
                    }
                }
            }
        }
    }

    .preview {
        opacity: .7;
        font-weight: 300;
        line-height: 1;
        width: 290px;
        height: 106px;
        overflow: hidden;
        padding: 6px;
        border-radius: 6px;
        background: rebeccapurple;
    }
}
